<p-toast></p-toast>
<div></div>
<!-- <pre>{{supportDocuments | json}}</pre> -->
<!-- <p-table
  [value]="supportDocuments"
  styleClass="p-datatable-striped"
  [tableStyle]="{ 'min-width': '50rem' }"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Department</th>
      <th>Group</th>
      <th>Button</th>
      <th>Type</th>
      <th>Document</th>
      <th>View</th>
      <th>Delete</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-document>
    <tr>
      <td>{{ document.ribbonsPageName }}</td>
      <td>{{ document.ribbonsPageGroupName }}</td>
      <td>{{ document.ribbonsPageGroupButtonName }}</td>
      <td>{{ document.documentType }}</td>
      <td>{{ document.displyDocumentName }}</td>
      <td>
        <p-button
          label="View"
          [raised]="true"
          severity="danger"
          (onClick)="downloadDocument(document.documentName)"
        ></p-button>
      </td>
      <td>
        <p-button
          label="Delete"
          [raised]="true"
          severity="danger"
          (onClick)="deleteDocument(document.id, document.documentPath)"
        ></p-button>
      </td>
    </tr>
  </ng-template>
</p-table> -->
<!-- <table>
  <ng-container *ngFor="let document of supportDocuments">
      <tr><td><h2>{{group.name}}</h2></td></tr>
      <tr *ngFor="let item of group.items"><td>{{item}}</td></tr>
  </ng-container>
</table> -->

<div class="d-flex justify-content-center">
  <div class="table-responsive p-0 w-100">
    <table class="table table-striped m-0">
      <thead class="table-primary position-sticky top-0">
        <tr>
          <th>Department</th>
          <th>Page</th>
          <th>Button</th>
          <th>Type</th>
          <th>Document</th>
          <th>Download</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="!isShowLoader; else other_content">
          <ng-container *ngFor="let document of supportDocuments">
            <tr>
              <td>{{ document.ribbonsPageName }}</td>
              <td>{{ document.ribbonsPageGroupName }}</td>
              <td>{{ document.ribbonsPageGroupButtonName }}</td>
              <td>{{ document.documentType }}</td>
              <td>{{ document.displyDocumentName }}</td>
              <td>
                <button
                  (click)="downloadDocument(document.documentName)"
                  class="btn btn-primary btn-sm"
                >
                  <i class="fas fa-download"></i>
                </button>
              </td>
              <td>
                <button
                  (click)="editDocuments(document.id)"
                  class="btn btn-primary btn-sm"
                >
                  <i class="fa fa-edit xs mx-1"></i>
                </button>
              </td>
              <td>
                <button
                  (click)="
                    confirmPosition(
                      'center',
                      document.id,
                      document.documentPath
                    )
                  "
                  class="btn btn-primary btn-sm"
                >
                  <i class="fa fa-trash xs mx-1"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-template #other_content>
          <tr id="loading-overlay">
            <td colspan="7" class="loading-icon text-center">
              <img
                src="../../../assets/images/loading.gif"
                style="width: 80px; height: 80px"
              />
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
</div>
<div class="card flex flex-wrap flex-column align-items-center gap-2">
  <p-toast></p-toast>
  <p-confirmDialog
    key="positionDialog"
    [position]="position"
    rejectButtonStyleClass="p-button-outlined"
  ></p-confirmDialog>
  <div class="flex flex-wrap justify-content-center gap-2">
    <!-- <p-button (click)="confirmPosition('center')" icon="pi pi-arrow-left" label="Right" styleClass="p-button-help"></p-button> -->
  </div>
</div>
<!-- <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-end mt-2 ">
      <li class="page-item"><a class="page-link" href="#">Previous</a>
      </li>
      <li class="page-item"><a class="page-link" href="#">1</a>
      </li>
      <li class="page-item"><a class="page-link" href="#">2</a>
      </li>
      <li class="page-item"><a class="page-link" href="#">3</a>
      </li>
      <li class="page-item"><a class="page-link" href="#">Next</a>
      </li>
    </ul>
  </nav> -->
