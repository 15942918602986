import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AddLevelsService } from '../../../services/add-levels.service';
import { RibbonPageGroup } from '../../../models/ribbon-page-group-model';
import { RibbonPage } from '../../../models/ribbon-page-model';
import { RibbonPageGroupButton } from '../../../models/ribbon-page-group-button-model';
import { Subscription } from 'rxjs';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-ribbons-page-group-button',
  templateUrl: './ribbons-page-group-button.component.html',
  styleUrl: './ribbons-page-group-button.component.css',
})
export class RibbonsPageGroupButtonComponent implements OnInit {
  @Output() onShowToastMessage = new EventEmitter<any>();
  private subscription = new Subscription();
  ribbonPageList: RibbonPage[] | undefined;
  selectedRibbonPage: RibbonPage | undefined;
  ribbonPageGroupList: RibbonPageGroup[] | undefined;
  selectedRibbonPageGroup: RibbonPageGroup | undefined;
  ribbonPageGroupButtonsGridData: RibbonPageGroupButton[] = [];
  ribbonPageGroupButtonsData: RibbonPageGroupButton[] = [];
  ribbonPageGroupButtonForm: FormGroup = new FormGroup({
    ribbonPagename: new FormControl(''),
    ribbonPageGroupname: new FormControl(''),
    ribbonPageGroupButtonname: new FormControl(''),
  });
  submitted = false;
  createdBy = '';
  addRibbionPageGroupButtonReq: RibbonPageGroupButton = {
    id: 0,
    idRibbonsPage: 0,
    ribbonsPageName: '',
    idRibbonsPageGroup: 0,
    ribbonsPageGroupName: '',
    ribbonsPageGroupButtonName: '',
    createdBy: this.createdBy,
    createdDate: new Date(),
    updatedBy: this.createdBy,
    updatedDate: new Date(),
    isDelete: false,
  };
  constructor(
    private addLevelsService: AddLevelsService,
    private formBuilder: FormBuilder
  ) {}
  loadRibbonPages() {
    this.addLevelsService.getAllRibbonPages().subscribe({
      next: (ribbonPages) => {
        this.ribbonPageList = ribbonPages;
      },
      error: (response) => {
        if (response.status === 401) {
          this.onShowToastMessage.emit({
            msg: 'Unauthorized',
            type: 'error',
          });
        }
        console.log(response);
      },
    });
  }
  loadRibbonPageGroups(ribbonPageId: number) {
    this.addLevelsService.getAllRibbonPageGroups(ribbonPageId).subscribe({
      next: (ribbonPageGroups) => {
        this.ribbonPageGroupList = ribbonPageGroups;
        this.selectedRibbonPageGroup = this.getRibbonPageGroupById(
          this.addRibbionPageGroupButtonReq.idRibbonsPageGroup
        );
      },
      error: (response) => {
        console.log(response);
      },
    });
  }
  loadRibbonPageGroupButtonsGrid() {
    this.addLevelsService.getAllGridRibbonPageGroupButtons().subscribe({
      next: (ribbonPageGroupButtons) => {
        this.ribbonPageGroupButtonsGridData = ribbonPageGroupButtons;
      },
      error: (response) => {
        console.log(response);
      },
    });
  }

  ngOnInit(): void {
    this.loadRibbonPages();
    this.ribbonPageGroupButtonForm = this.formBuilder.group({
      ribbonPagename: ['', Validators.required],
      ribbonPageGroupname: ['', Validators.required],
      ribbonPageGroupButtonname: ['', Validators.required],
    });
    this.loadRibbonPageGroupButtonsGrid();
    this.subscription = this.addLevelsService.currentRibbionPageData.subscribe(
      (data) => {
        this.ribbonPageList = data;
      }
    );
    this.subscription =
      this.addLevelsService.currentRibbionPageGroupData.subscribe((data) => {
        this.ribbonPageGroupList = data;
      });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.ribbonPageGroupButtonForm.controls;
  }
  addRibbonPageGroupButton() {
    this.submitted = true;
    if (this.ribbonPageGroupButtonForm.invalid) {
      return;
    }
    this.addRibbionPageGroupButtonReq.idRibbonsPage =
      this.selectedRibbonPage?.id;
    this.addRibbionPageGroupButtonReq.idRibbonsPageGroup =
      this.selectedRibbonPageGroup?.id;

    this.addLevelsService
      .addRibbonPageGroupButton(this.addRibbionPageGroupButtonReq)
      .subscribe({
        next: (addRibbionPageGroupReq) => {
          this.loadRibbonPageGroupButtonsGrid();
          this.clearRibbonPageGroupButtonFields();
          this.onShowToastMessage.emit({
            msg: 'Button Saved Successfully',
            type: 'success',
          });
        },
        error: (response) => {
          console.log(response);
          if (response.status === 401) {
            this.onShowToastMessage.emit({
              msg: 'Unauthorized',
              type: 'error',
            });
          } else {
            this.onShowToastMessage.emit({
              msg: 'Error while Saving Buttons',
              type: 'error',
            });
          }
        },
      });
  }
  onRibbonPageChange() {
    this.addLevelsService
      .getAllRibbonPageGroups(this.selectedRibbonPage?.id)
      .subscribe({
        next: (ribbonPageGroups) => {
          this.ribbonPageGroupList = ribbonPageGroups;
        },
        error: (response) => {
          if (response.status === 401) {
            this.onShowToastMessage.emit({
              msg: 'Unauthorized',
              type: 'error',
            });
          }
        },
      });
  }

  clearRibbonPageGroupButtonFields() {
    this.addRibbionPageGroupButtonReq.id = 0;
    this.addRibbionPageGroupButtonReq.idRibbonsPage = 0;
    this.addRibbionPageGroupButtonReq.idRibbonsPageGroup = 0;
    this.addRibbionPageGroupButtonReq.ribbonsPageName = '';
    (this.addRibbionPageGroupButtonReq.ribbonsPageGroupName = ''),
      (this.addRibbionPageGroupButtonReq.ribbonsPageGroupButtonName = ''),
      (this.addRibbionPageGroupButtonReq.createdBy = ''),
      (this.addRibbionPageGroupButtonReq.createdDate = new Date()),
      (this.addRibbionPageGroupButtonReq.updatedBy = ''),
      (this.addRibbionPageGroupButtonReq.updatedDate = new Date());
    this.selectedRibbonPage = undefined;
    this.selectedRibbonPageGroup = undefined;
  }
  getRibbonPageGroupButtonById(id: number) {
    this.ribbonPageGroupButtonsData = JSON.parse(
      JSON.stringify(this.ribbonPageGroupButtonsGridData)
    );
    var obj = this.ribbonPageGroupButtonsData.filter(function (node) {
      return node.id == id;
    });

    return obj[0];
  }
  getRibbonPageById(id: number) {
    return this.ribbonPageList?.filter(function (node) {
      return node.id == id;
    })[0];
  }
  getRibbonPageGroupById(id: number) {
    return this.ribbonPageGroupList?.filter(function (node) {
      return node.id == id;
    })[0];
  }
  async editRibbionPageGroupButton(Id: number) {
    this.addRibbionPageGroupButtonReq = this.getRibbonPageGroupButtonById(Id);
    this.selectedRibbonPage = this.getRibbonPageById(
      this.addRibbionPageGroupButtonReq.idRibbonsPage
    );
    this.loadRibbonPageGroups(this.addRibbionPageGroupButtonReq.idRibbonsPage);
  }

  async deleteRibbonPageGroupButton(ribbonPageGroupButtonId: number) {
    this.addLevelsService
      .deleteRibbonPageGroupButton(ribbonPageGroupButtonId)
      .subscribe({
        next: (ribbonPageGroupButtons) => {
          this.ribbonPageGroupButtonsGridData = ribbonPageGroupButtons;
          this.onShowToastMessage.emit({
            msg: 'Button deleted Successfully',
            type: 'success',
          });
        },
        error: (response) => {
          if (response.status === 401) {
            this.onShowToastMessage.emit({
              msg: 'Unauthorized',
              type: 'error',
            });
          } else {
            this.onShowToastMessage.emit({
              msg: 'Error while deleting Button',
              type: 'error',
            });
          }
        },
      });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
